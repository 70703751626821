export enum AnbefalingType {
    RESTAURANT = "RESTAURANT",
    MUSEUM = "MUSEUM",
    STRAND = "STRAND",
    BAR = "BAR",
    AKTIVITET = "AKTIVITET",
}

export function visningstekstForKategori(kategori): string {
    switch (kategori) {
        case AnbefalingType.AKTIVITET:
            return "Aktivitet"
        case AnbefalingType.BAR:
            return "Bar"
        case AnbefalingType.RESTAURANT:
            return "Restaurant"
        case AnbefalingType.STRAND:
            return "Strand"
        case AnbefalingType.MUSEUM:
            return "Museum"
    }
}
