import ReactModal from "react-modal"
import { Recommendation } from "../types/supabase"
import style from "./anbefalinger.module.css"

export function AnbefalingModal({
    data,
    lukk,
    vis,
}: {
    data?: Recommendation
    lukk: () => void
    vis: boolean
}) {
    if (!data) return null
    return (
        <ReactModal ariaHideApp={false} isOpen={vis}>
            <div className={style.anbefalingModal}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                        <h2>{data.name}</h2>

                        <p>
                            <strong>Anbefalt av</strong>
                        </p>
                        <p>{data.user}</p>
                        <p>
                            <strong>Rating</strong>
                        </p>
                        <p>{data.rating}</p>
                        <p>
                            <strong>Beskrivelse</strong>
                        </p>
                        <p>{data.description}</p>
                        <p>
                            <strong>Adresse</strong>
                        </p>
                        <p>{data.address}</p>
                        {data.url && (
                            <a className={"url"} href={data.url}>
                                Nettside
                            </a>
                        )}
                    </div>
                    <div>
                        <h3>Bilder</h3>
                    </div>
                </div>

                <button className={style.lukk} onClick={lukk}>
                    Lukk
                </button>
            </div>
        </ReactModal>
    )
}
