import { useEffect, useState } from "react"
import supabase from "../supabaseconfig"
import { Recommendation } from "../types/supabase"
import { AnbefalingModal } from "./Anbefaling"
import style from "./anbefalinger.module.css"
import { LeggTilAnbefalingModal } from "./LeggTilModal"
import { AnbefalingType, visningstekstForKategori } from "./utils"

export default function Anbefalinger() {
    const [visLeggTilNyModal, setVisLeggTilNyModal] = useState(false)
    const [visFiltrering, setVisFiltrering] = useState(false)
    const [anbefalingDetaljevisning, setAnbefalingDetaljevisning] = useState<
        Recommendation | undefined
    >()
    const [recommendations, setRecommendations] = useState<Recommendation[]>([])
    const [kategorifilter, setKategorifilter] = useState<AnbefalingType[]>(
        Object.values(AnbefalingType),
    )

    useEffect(() => {
        getRecommendations()
    }, [])

    async function getRecommendations(): Promise<void> {
        let { data, error } = await supabase()
            .from("recommendations")
            .select("*")

        if (error) {
            console.error("Error fetching recommendations:", error)
            return
        }

        if (data) {
            setRecommendations(data)
        }
    }

    return (
        <div>
            <div className="page">
                <h1 className="topHeader">ANBEFALINGER</h1>
            </div>
            <div className="underPage">
                <div className={style.layout}>
                    <button
                        style={{ alignSelf: "end" }}
                        type="button"
                        onClick={() => {
                            setVisLeggTilNyModal(true)
                        }}
                    >
                        + Legg til ny anbefaling
                    </button>
                    <button
                        style={{ alignSelf: "end", marginTop: "1rem" }}
                        type="button"
                        onClick={() => {
                            setVisFiltrering(!visFiltrering)
                        }}
                    >
                        Filtrer
                    </button>
                    {visFiltrering && (
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            {Object.values(AnbefalingType).map((kategori) => {
                                return (
                                    <label key={kategori}>
                                        <input
                                            onChange={(event) => {
                                                const oppdaterListe = event
                                                    .target.checked
                                                    ? kategorifilter.concat([
                                                          kategori,
                                                      ])
                                                    : kategorifilter.filter(
                                                          (it) =>
                                                              it !== kategori,
                                                      )
                                                setKategorifilter(oppdaterListe)
                                            }}
                                            type="checkbox"
                                            checked={kategorifilter.some(
                                                (it) => kategori === it,
                                            )}
                                        />
                                        {visningstekstForKategori(kategori)}
                                    </label>
                                )
                            })}
                        </div>
                    )}
                    {recommendations
                        .filter((it) =>
                            kategorifilter.some(
                                (kategori) => kategori === it.type,
                            ),
                        )
                        .map((it: Recommendation) => (
                            <AnbefalingMinivisning
                                data={it}
                                setVisning={() =>
                                    setAnbefalingDetaljevisning(it)
                                }
                                key={it.id}
                            />
                        ))}
                    {recommendations.filter((it) =>
                        kategorifilter.some((kategori) => kategori === it.type),
                    ).length === 0 && (
                        <div className={style.ingenResultater}>
                            <p>
                                <strong>Ingen resultater</strong>
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <AnbefalingModal
                data={anbefalingDetaljevisning}
                lukk={() => setAnbefalingDetaljevisning(undefined)}
                vis={!!anbefalingDetaljevisning}
            />
            <LeggTilAnbefalingModal
                vis={visLeggTilNyModal}
                lukk={() => setVisLeggTilNyModal(false)}
            />
        </div>
    )
}

function AnbefalingMinivisning({
    data,
    setVisning,
}: {
    key: number
    data: Recommendation
    setVisning: () => void
}) {
    function hentIkon(type: string): string {
        switch (type) {
            case AnbefalingType.RESTAURANT:
                return "🍽️"
            case AnbefalingType.MUSEUM:
                return "🏛️"
            case AnbefalingType.BAR:
                return "🍸"
            case AnbefalingType.AKTIVITET:
                return "🥾"
            case AnbefalingType.STRAND:
                return "🏖️"
            default:
                return "❓"
        }
    }

    return (
        <div className={style.anbefaling}>
            <div>
                <div>
                    <h2>
                        {hentIkon(data.type)} {data.name}
                    </h2>
                </div>

                <p>
                    <strong>Rating</strong>
                </p>
                <p>{data.rating}</p>

                <p>
                    <strong>Adresse</strong>
                </p>
                <p>{data.address}</p>
                {data.url && (
                    <a className={"url"} href={data.url}>
                        Nettside
                    </a>
                )}
            </div>

            <button style={{ marginTop: 32 }} onClick={setVisning}>
                Se mer
            </button>
        </div>
    )
}
