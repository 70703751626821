import "./App.css"
import React, { useEffect, useState, useCallback } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Hjem from "./Pages/Hjem"
import Informasjon from "./Pages/Informasjon"
import Huskeliste from "./Pages/Huskeliste"
import Anbefalinger from "./anbefalinger/Anbefalinger"
import Layout from "./Pages/Layout"
import { Auth } from "@supabase/auth-ui-react"
import { ThemeSupa } from "@supabase/auth-ui-shared"
import supabase from "./supabaseconfig"
import { Feilmelding } from "./components/Feilmelding"
import { User } from "./types/supabase"
import { useBrukerContext } from "./BrukerContext"

export function Innhold() {
    const [session, setSession] = useState(null)
    const [harTilgang, setHarTilgang] = useState(false)
    const { oppdaterBruker } = useBrukerContext()

    useEffect(() => {
        supabase()
            .auth.getSession()
            .then(({ data: { session } }) => {
                setSession(session)
            })

        const {
            data: { subscription },
        } = supabase().auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    const getUserRole = useCallback(async (): Promise<void> => {
        let { data, error } = await supabase()
            .from("users")
            .select("*")
            .returns<User[]>()

        if (error) {
            return <Feilmelding tekst={"Feil ved henting av data"} />
        }

        if (data.length > 0) {
            setHarTilgang(true)
            oppdaterBruker(data[0].name)
        }
    }, [oppdaterBruker])

    useEffect(() => {
        if (session) {
            getUserRole()
        }
    }, [session, getUserRole])

    if (!session) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <h1>Logg inn</h1>
                <h1 style={{ marginBottom: "4rem" }}>👇</h1>
                <Auth
                    supabaseClient={supabase()}
                    appearance={{ theme: ThemeSupa }}
                    providers={["google"]}
                    onlyThirdPartyProviders
                />
            </div>
        )
    } else if (!harTilgang) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <h1>Du har ikke tilgang til denne siden</h1>
                <p>Gi beskjed til Emilie så legger hun deg til</p>
            </div>
        )
    } else {
        return (
            <BrowserRouter
                future={{
                    v7_relativeSplatPath: true,
                    v7_startTransition: true,
                }}
            >
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Hjem />} />
                        <Route path="informasjon" element={<Informasjon />} />
                        <Route path="anbefalinger" element={<Anbefalinger />} />
                        <Route path="huskeliste" element={<Huskeliste />} />
                        <Route path="*" element={<Hjem />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        )
    }
}
