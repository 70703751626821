import { useContext, useState } from "react"
import ReactModal from "react-modal"
import { BrukerContext } from "../BrukerContext"
import supabase from "../supabaseconfig"
import { Recommendation } from "../types/supabase"
import style from "./anbefalinger.module.css"
import { AnbefalingType, visningstekstForKategori } from "./utils"

export function LeggTilAnbefalingModal({
    vis,
    lukk,
}: {
    vis: boolean
    lukk: () => void
}) {
    const { bruker } = useContext(BrukerContext)
    const [type, setType] = useState<AnbefalingType | undefined>()
    const [feilmelding, setFeilmeding] = useState<string | undefined>()
    const [visSuksess, setVisSuksess] = useState(false)
    const [laster, setLaster] = useState(false)
    const [anbefaling, setAnbefaling] = useState<Recommendation>({
        user: bruker,
    })

    function validerInput(): boolean {
        //TODO Valider
        return true
    }

    async function lagreAnbefaling() {
        if (validerInput()) {
            setLaster(true)

            let { error } = await supabase()
                .from("recommendations")
                .insert(anbefaling)

            if (error) {
                console.error("Feil ved opplasting av anbefaling")
                setFeilmeding("En feil skjedde under lagring av anbefaling.")
            } else {
                setVisSuksess(true)
            }
            setLaster(false)
        } else {
            setFeilmeding("Du må fylle ut manglende felter")
        }
    }

    const visInnhold = !laster && !feilmelding && !visSuksess

    return (
        <ReactModal ariaHideApp={false} isOpen={vis}>
            <div className={style.modal}>
                {laster && <h2>Laster...</h2>}
                {feilmelding && <h2>{feilmelding}</h2>}
                {visSuksess && (
                    <>
                        <h2>Din anbefaling er lagret! 🎉</h2>
                        <button onClick={lukk}>Lukk</button>
                    </>
                )}
                {visInnhold && (
                    <>
                        <h2 style={{ marginBottom: "3rem" }}>
                            Legg til ny anbefaling
                        </h2>

                        <label className={style.inputPar}>
                            Hva skal du legge inn?
                            <select
                                value={AnbefalingType[type]}
                                onChange={(event) => {
                                    setAnbefaling({
                                        ...anbefaling,
                                        type: event.target.value,
                                    })
                                    setType(toEnum(event.target.value))
                                }}
                            >
                                <option value={undefined}></option>
                                {Object.keys(AnbefalingType).map((it) => (
                                    <option key={it} value={it}>
                                        {visningstekstForKategori(
                                            AnbefalingType[it],
                                        )}
                                    </option>
                                ))}
                            </select>
                        </label>

                        {type && (
                            <NyAnbefalingSkjema
                                type={type}
                                anbefaling={anbefaling}
                                setAnbefaling={setAnbefaling}
                            />
                        )}

                        <div className={style.knapper}>
                            <button onClick={lukk}>Avbryt</button>
                            <button onClick={() => lagreAnbefaling()}>
                                Lagre
                            </button>
                        </div>
                    </>
                )}
            </div>
        </ReactModal>
    )
}

function NyAnbefalingSkjema({
    type,
    anbefaling,
    setAnbefaling,
}: {
    type: AnbefalingType
    anbefaling: Recommendation
    setAnbefaling: (r: Recommendation) => void
}) {
    return (
        <>
            <label className={style.inputPar}>
                Navn på {type.toLocaleLowerCase()}
                <input
                    type="text"
                    onChange={(event) =>
                        setAnbefaling({
                            ...anbefaling,
                            name: event.target.value,
                        })
                    }
                />
            </label>

            <label className={style.inputPar}>
                Beskrivelse
                <textarea
                    type="text"
                    onChange={(event) =>
                        setAnbefaling({
                            ...anbefaling,
                            description: event.target.value,
                        })
                    }
                />
            </label>

            <label className={style.inputPar}>
                Terningkast ⭐️
                <input
                    type="number"
                    onChange={(event) =>
                        setAnbefaling({
                            ...anbefaling,
                            rating: event.target.value,
                        })
                    }
                />
            </label>

            <label className={style.inputPar}>
                Adresse
                <input
                    type="text"
                    onChange={(event) =>
                        setAnbefaling({
                            ...anbefaling,
                            address: event.target.value,
                        })
                    }
                />
            </label>

            <label className={style.inputPar}>
                Nettside
                <input
                    type="text"
                    onChange={(event) =>
                        setAnbefaling({
                            ...anbefaling,
                            url: event.target.value,
                        })
                    }
                />
            </label>
        </>
    )
}

export function toEnum(s?: string): AnbefalingType | undefined {
    return s ? AnbefalingType[s as keyof typeof AnbefalingType] : undefined
}
